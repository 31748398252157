var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.currentError
    ? _c("div", { staticClass: "ValidationError" }, [
        _vm._v(" " + _vm._s(_vm.$t(_vm.currentError)) + " ")
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }