export default {
  data() {
    return {
      errors: [],
      modify: true,
    };
  },

  methods: {
    validation(e) {
      const element = e.target;
      if (element.validity?.valid) {
        this.removeValidationError(element);
      } else if (!element.classList.contains('validation-error')) {
        this.addValidationError(element);
      } else {
        this.setValidationError(element);
      }
    },

    removeValidationError(element) {
      element.classList.remove('validation-error');

      this.errors = this.errors.filter(error => error.name !== element.name);
    },

    setValidationError(element, customText) {
      const { name, el } = this.errors.find(error => error.name === element.name);

      const updatedError = { name, el, text: customText ?? this.getValidationMessage(element) };
      this.errors = this.errors.filter(error => error.name !== name);
      this.errors.push(updatedError);
    },

    addValidationError(element, customText) {
      element.classList.add('validation-error');

      const newError = { name: element.name, el: element, text: customText ?? this.getValidationMessage(element) };
      this.errors.push(newError);
    },

    getValidationMessage(element) {
      const customErrorText = element.getAttribute('error');
      return customErrorText || element.validationMessage;
    },

    areErrors() {
      return (this.errors.length > 0);
    },

    checkAllFields(currentForm) {
      if (currentForm.checkValidity() === false) {
        const inputs = document.forms[0].querySelectorAll('input');
        inputs.forEach((input) => {
          this.validation({ target: input });
        });

        const textareas = document.forms[0].querySelectorAll('textarea');
        textareas.forEach((textarea) => {
          this.validation({ target: textarea });
        });
      }
    },

    checkSpecificfield(tag) {
      const element = document.forms[0].querySelector(`[name=${tag}]`);
      this.validation({ target: element });
    },

    forceErrorElement(element, text) {
      const tempClassName = 'validation-error';
      if (!element.classList.contains(tempClassName)) {
        this.addValidationError(element, text);
      } else {
        this.setValidationError(element, text);
      }

      setTimeout(() => {
        element.classList.remove(tempClassName);
      }, 1500);
    },

    forceErrorElementByTag(tag, text) {
      const element = document.querySelector(`[name=${tag}]`);
      if (element) this.forceErrorElement(Object.assign(element, { name: tag }), text);
    },

    checkSpecificValidation(validationName, questionAnswer) {
      const validationkey = Object.keys(validationName)[0];
      const validationEntries = Object.entries(validationName)[0];

      switch (validationkey) {
        case 'required': return this.checkRequiredValidation(questionAnswer, validationEntries);
        case 'attempts': return this.checkAttemptsValidation(questionAnswer, validationEntries);
        default: return true;
      }
    },

    checkRequiredValidation(questionAnswer, validationEntries) {
      let answered = questionAnswer.answer;
      if (typeof questionAnswer.answer === 'object') {
        answered = Array.isArray(questionAnswer.answer) ?
          questionAnswer.answer.length :
          Object.values(questionAnswer.answer).every(value => value !== undefined);
      }

      if (validationEntries && !answered) {
        this.forceErrorElementByTag(questionAnswer.key, 'VALIDATION.REQUIRED.DEFAULT');
        return false;
      }

      return true;
    },

    checkAttemptsValidation(questionAnswer, validationEntries) {
      const { answer } = questionAnswer;

      let valid = false;
      if (Array.isArray(answer) && typeof answer[0] !== 'undefined') {
        const isCorrect = answer[0];
        const attempts = answer[1];
        const minAttempts = validationEntries[1];
        valid = (isCorrect || attempts > minAttempts);
      }

      if (!valid) {
        this.forceErrorElementByTag(questionAnswer.key, 'VALIDATION.ATTEMPTS.DEFAULT');
        return false;
      }

      return true;
    },

  },
};
